import React, { Component } from 'react'
import popupStyles from './smallPopup.module.css'
import Button from '../../common/defaultButton/defaultButton'
import fonts from '../../../styles/fonts.module.css'
import CancelButton from '../../reservation/buttons/cancelButton'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'

/*
Props:
  messageHeading
  messageBody
  buttonsText
  redirectToUrl
  closePopup
  redirectToUrl
*/
class ConfirmPopup extends Component {
  componentDidMount() {
    document.body.style = 'overflow: hidden'
  }
  componentWillUnmount() {
    document.body.style = 'overflow: auto'
  }

  render() {
    return (
      <>
        <div className={popupStyles.mask}></div>
        <div className={popupStyles.popup}>
          <h4 className={fonts.heading3noMargin}>{this.props.intl.formatMessage({ id: 'venuePage.warningHeading' })}</h4>
          <p className={fonts.smallParagraph}>{this.props.intl.formatMessage({ id: 'venuePage.warningBody' })}</p>
          <div className={popupStyles.footer}>
            <div className={popupStyles.footerLeft}>
              <Button type="smallSecondary" onClick={this.props.closePopup} buttonText={this.props.intl.formatMessage({ id: 'buttons.continueWithReservation' })} />
            </div>
            <div className={popupStyles.footerRight}>
              <CancelButton afterCancelReservationSuccess={this.props.redirectToUrl} buttonText={this.props.intl.formatMessage({ id: 'buttons.leavePage' })} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(ConfirmPopup)
