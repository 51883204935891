import React from 'react'
import { connect } from 'react-redux'
import styles from  './navLogo.module.css'
import SokoshotelsLogo from '../../../../static/svgs/sokoshotels_logo.svg'

const Logo = props => {
  let pathUrl = '/'
  if (props.locale === '/' || props.locale === '/fi/' || props.reduxLanguage === 'fi') {
    pathUrl = '/'
  }
  if (props.locale?.includes('/sv/') || props.reduxLanguage === 'sv') {
    pathUrl = '/sv'
  }
  if (props.locale?.includes('/en/') || props.reduxLanguage === 'en') {
    pathUrl = '/en'
  }
  if (props.locale?.includes('/et/') || props.reduxLanguage === 'et') {
    pathUrl = '/et'
  }

  return (
      <a href={`${process.env.GATSBY_SH_URL}${pathUrl}`}>
        <div className={styles.wrapper}>
          <SokoshotelsLogo alt="sokoshotels" />
        </div>
      </a>
  )
}

const mapStateToProps = state => ({
  reduxLanguage: state.language,
})

export default connect(mapStateToProps)(Logo)
