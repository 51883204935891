import React, { Component } from 'react'
import navLinkStyles from '../navLinks/navLinks.module.css'
import burgerMenuStyles from './burgerMenu.module.css'
import NavLinks from '../navLinks/navLinks'
import LanguageStyles from './languageSelection.module.css'
import FindReservationButtonBurger from './findReservationButtonBurger'
import { connect } from 'react-redux'
import { injectIntl } from '../../../../plugins/gatsby-plugin-intl-custom'
import LanguageSelectionBurger from "./languageSelectionBurger";

class BurgerMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clickToggle: true,
    }

    this.handleToggleOnKeypress = this.handleToggleOnKeypress.bind(this)
  }

  handleClick = () => {
    this.setState(state => {
      return {
        clickToggle: !state.clickToggle,
      }
    })
    if (this.state.clickToggle) {
      document.querySelector('#popUpMenu').style.display = 'block'
      document.querySelector('#subRibbonBurger').style.display = 'block'
      document.querySelector('#findReservationButtonBurger').style.display = 'block'
      document.querySelector('#languageSelectionBurger').style.display = 'block'
      document.querySelector('#burgerIcon').style.display = 'none'
      document.querySelector('#closeIcon').style.display = 'inline-block'
      document.body.classList.add('popupOpen')
    } else {
      document.querySelector('#popUpMenu').style.display = 'none'
      document.querySelector('#subRibbonBurger').style.display = 'none'
      document.querySelector('#findReservationButtonBurger').style.display = 'none'
      document.querySelector('#languageSelectionBurger').style.display = 'none'
      document.querySelector('#burgerIcon').style.display = 'inline-block'
      document.querySelector('#closeIcon').style.display = 'none'
      document.body.classList.remove('popupOpen')
    }
  }

  handleToggleOnKeypress(e) {
    e.preventDefault()
    // if user presses enter key the additional view gets toggled
    if (e.charCode === 13) {
      this.handleClick()
    }
  }

  render() {
    return (
      <div className={burgerMenuStyles.burgerMenuWrapper}>
        <div id="burgerIcon" className={burgerMenuStyles.burgerMenu} onClick={this.handleClick} onKeyPress={e => this.handleToggleOnKeypress(e)} tabIndex="0" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M21 7.75H3v-1.5h18v1.5Zm0 5H3v-1.5h18v1.5Zm-18 5h18v-1.5H3v1.5Z"></path></svg>
        </div>
        <div id="closeIcon" className={burgerMenuStyles.closeMenu} onClick={this.handleClick} onKeyPress={e => this.handleToggleOnKeypress(e)} tabIndex="0" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="m12 13.06-6.47 6.47-1.06-1.06L10.94 12 4.47 5.53l1.06-1.06L12 10.94l6.47-6.47 1.06 1.06L13.06 12l6.47 6.47-1.06 1.06L12 13.06Z"></path></svg>
        </div>

        <div id="popUpMenu" className={`${burgerMenuStyles.popUpMenu} ${navLinkStyles.mobileNav} ${LanguageStyles.mobileNav}`}>
          <NavLinks />
          <LanguageSelectionBurger />
          <FindReservationButtonBurger onClick={this.handleClick} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  reduxState: state,
})

export default connect(mapStateToProps)(injectIntl(BurgerMenu))
