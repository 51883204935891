import React, { Component } from 'react'
import footerStyles from './footer.module.css'
import { connect } from 'react-redux'
import fonts from '../../styles/fonts.module.css'
import FooterLogo from '../navigation/navHeader/footerLogo'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      localizedLinks: hardCodedData()['fi'],
    }
    this.openConsentChooser = this.openConsentChooser.bind(this)
  }

  componentDidMount() {
    this.setState({
      localizedLinks: hardCodedData()[this.props.reduxLanguage || 'fi'],
    })
  }

  colList = data =>
    Object.values(data).map((item, index) => {
      return (
        <div
          key={index}
          className={fonts.footerLink + ' ' + (item.class === 'title' ? footerStyles.titleItem : footerStyles.item) + ' ' + (item.class === 'hotelsButton' ? footerStyles.hotelsButtonWrapper : '')}
        >
          <a
            href={item.link.startsWith('http') ? item.link : process.env.GATSBY_SH_URL + item.link}
            target={item.link.startsWith('http') ? '_blank' : ''}
            className={(item.class === 'title' ? footerStyles.title : '') + ' ' + (item.class === 'hotelsButton' ? footerStyles.hotelsButton : '')}
            rel={item.link.startsWith('http') ? 'noopener noreferrer' : ''}
          >
            {item.name}
          </a>
        </div>
      )
    })

  openConsentChooser() {
    try {
      // eslint-disable-next-line
      UC_UI.showSecondLayer()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    return (
      <div className={footerStyles.footer}>
        <div className={footerStyles.wrapper}>
          <FooterLogo locale={this.props.locale} />
          <div className={footerStyles.bottomFooter}>
            {this.colList(this.state.localizedLinks.footer)}
            <button className={footerStyles.cookieButton} onClick={() => this.openConsentChooser()}>
              {this.state.localizedLinks.cookieConsent}
            </button>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  reduxLanguage: state.language,
})

export default connect(mapStateToProps)(Footer)

const hardCodedData = () => {
  return {
    fi: {
      footer: {
        row1: {
          name: 'Varausehdot',
          link: '/varausehdot',
        },
        row2: {
          name: 'Käyttöehdot',
          link: '/kayttoehdot',
        },
        row3: {
          name: 'Tietosuoja',
          link: '/tietosuoja',
        },
        row4: {
          name: 'Copyright',
          link: '/copyright',
        },
      },
      cookieConsent: 'Evästehallinta',
    },
    sv: {
      footer: {
        row1: {
          name: 'Regler och villkor för bokningar',
          link: '/sv/regler-och-villkor-for-bokningar',
        },
        row2: {
          name: 'Regler och villkor',
          link: '/sv/regler-och-villkor',
        },
        row3: {
          name: 'Dataskydd',
          link: '/sv/registerdeklaration',
        },
        row4: {
          name: 'Copyright',
          link: '/sv/copyright',
        },
      },
      cookieConsent: 'Inställningar för cookies',
    },
    en: {
      footer: {
        row1: {
          name: 'Terms of reservation',
          link: '/en/terms-of-reservation',
        },
        row2: {
          name: 'Terms of use',
          link: '/en/terms-of-use',
        },
        row3: {
          name: 'Privacy policy',
          link: '/en/privacy-policy',
        },
        row4: {
          name: 'Copyright',
          link: '/en/copyright',
        },
      },
      cookieConsent: 'Cookie settings',
    },
    et: {
      footer: {
        row1: {
          name: 'Broneerimistingimused',
          link: '/et/broneerimistingimused',
        },
        row2: {
          name: 'Kasutustingimused',
          link: '/et/kasutustingimused',
        },
        row3: {
          name: 'Privaatsus',
          link: '/et/andmekaitsejuhendid',
        },
        row4: {
          name: 'Autoriõigused',
          link: '/et/autorioigused',
        },
      },
      cookieConsent: 'Küpsiste sätted',
    },
  }
}
