import React, { Component } from 'react'
import SubRibbon from '../navLinks/subRibbon'
import { connect } from 'react-redux'
import ConfirmPopup from './confirmPopup'
import { injectIntl, navigate } from '../../../../plugins/gatsby-plugin-intl-custom'

class NavLinks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmationPopupOpen: false,
      targetURL: '',
      isExternalURL: false,
    }

    this.openConfirmationPopup = this.openConfirmationPopup.bind(this)
    this.redirectToUrl = this.redirectToUrl.bind(this)
    this.closePopup = this.closePopup.bind(this)
  }

  openConfirmationPopup(e, targetURL, isExternalURL) {
    // If user created pre reservation show "leaving page" confirmation popup
    if (this.isVenueDetailsPage() && this.props.reduxState.data.createReservation) {
      e.preventDefault()
      const open = this.state.confirmationPopupOpen
      this.setState({ confirmationPopupOpen: !open, targetURL: targetURL, isExternalURL: isExternalURL })
    } else if (!isExternalURL) {
      // If user did not initate reservation process and clicked on internal link, use gatsby navigation API
      navigate(this.state.targetURL, { replace: true, state: this.props.reduxState })
    }
  }

  isVenueDetailsPage() {
    return typeof window !== 'undefined' && window.location.pathname && window.location.pathname.includes('SokosHotel')
  }

  redirectToUrl() {
    if (!this.state.isExternalURL) {
      navigate(this.state.targetURL, { replace: true, state: this.props.reduxState })
    } else if (typeof window !== 'undefined') {
      window.location = this.state.targetURL
    }
  }

  closePopup() {
    const open = this.state.confirmationPopupOpen
    this.setState({ confirmationPopupOpen: !open, targetURL: '', isExternalURL: false })
  }

  isConfirmationPopupOpen() {
    return !this.props.reduxState.data.cancelReservation && this.state.confirmationPopupOpen
  }

  render() {
    return (
      <>
        <SubRibbon locale={this.props.locale} reduxLang={this.props.reduxLanguage} data={hardCodedData()} openConfirmationPopup={this.openConfirmationPopup} />
        {this.isConfirmationPopupOpen() && <ConfirmPopup redirectToUrl={this.redirectToUrl} closePopup={this.closePopup} />}
      </>
    )
  }
}

const mapStateToProps = state => ({
  reduxState: state,
  reduxLanguage: state.language,
})

export default connect(mapStateToProps)(injectIntl(NavLinks))

const hardCodedData = () => {
  return {
    fi: {
      MeetingsAndEvents: {
        name: 'Kokous & Juhlat',
        link: '',
        selected: true,
        sublinks: {
          Link01: {
            name: 'Kokoukset',
            link: '/kokoustilat',
          },
          Link02: {
            name: 'Juhlat',
            link: '/juhlatilat',
          },
          Link03: {
            name: 'Saunat',
            link: '/saunat',
          },
          Link04: {
            name: 'Tarjouspyyntö',
            link: '/tarjouspyynto',
          },
        },
      },
    },
    sv: {
      MeetingsAndEvents: {
        name: 'Möten Och Evenemang',
        link: '',
        selected: true,
        sublinks: {
          Link01: {
            name: 'Mötesförfrågan',
            link: '/sv/offertforfragan',
          },
        },
      },
    },
    en: {
      MeetingsAndEvents: {
        name: 'Meetings & Events',
        link: '',
        selected: true,
        sublinks: {
          Link01: {
            name: 'Meetings & Events - Request for offer',
            link: '/en/request-for-offer',
          },
        },
      },
    },
    et: {
      MeetingsAndEvents: {
        name: 'Konverentsid & Sündmused',
        link: '',
        selected: true,
        sublinks: {
          Link01: {
            name: 'Konverentsid & Sündmused - Küsi pakkumist',
            link: '/en/request-for-offer',
          },
        },
      },
    },
  }
}
