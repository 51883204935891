import React from 'react'
import buttonStyles from './defaultButton.module.css'
import LoadingAnimation from '../../../../static/svgs/loading_animation.svg'

/* Props
  type (default | primary | secondary | destructive | smallDefault | smallPrimary | smallSecondary | smallDestructive)
  onClick
  buttonText
  disabled
  isLoadingButton
*/

export const DefaultButton = props => {
  const buttonClassName = (() => {
    switch (props.type) {
      case 'default':
        return buttonStyles.default
      case 'primary':
        return buttonStyles.primary
      case 'secondary':
        return buttonStyles.secondary
      case 'destructive':
        return buttonStyles.destructive
      case 'smallDefault':
        return buttonStyles.smallDefault
      case 'smallDefaultLight':
        return buttonStyles.smallDefaultLight
      case 'smallPrimary':
        return buttonStyles.smallPrimary
      case 'smallSecondary':
        return buttonStyles.smallSecondary
      case 'smallDestructive':
        return buttonStyles.smallDestructive
      default:
        return buttonStyles.default
    }
  })()

  return (
    <button
      type={props.type}
      className={`${buttonClassName} ${props.disabled ? buttonStyles.disabled : ''} ${props.isLoadingButton ? buttonStyles.loadingButton : ''}`}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.isLoadingButton ? <LoadingAnimation className={buttonStyles.animation} /> : props.buttonText}
    </button>
  )
}

export default DefaultButton
