import React from 'react'
import styles from './findReservationButton.module.css'
import { connect } from 'react-redux'
import { Link, FormattedMessage } from '../../../../plugins/gatsby-plugin-intl-custom'

const FindReservationButton = ({ reduxState, className }) => {
  const stateForRedirection = { ...reduxState }
  stateForRedirection.data = {
    edges: [],
    venueAvailability: undefined,
    venueProducts: [],
    reservation: undefined,
    createReservation: undefined,
    confirmReservation: undefined,
    cancelReservation: undefined,
    queryError: undefined,
  }
  stateForRedirection.search.reservationId = ''
  stateForRedirection.search.reservationLastname = ''
  stateForRedirection.search.reservationURLEncryptedPart = ''

  return (
    <div className={`${styles.wrapper} ${className && className}}`}>
      <Link to="/reservation" state={stateForRedirection}>
        <span className={styles.subWrapper}>
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
            <path fill-rule="evenodd" d="M11.68 11.74a6 6 0 1 1 1.06-1.06L16 13.94 14.94 15l-3.26-3.26ZM12.5 7a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Z" fill="currentColor"></path>
          </svg>
        </span>
        <FormattedMessage id="header.findReservation" />
      </Link>
    </div>
  )
}

const mapStateToProps = state => ({
  reduxState: state,
})

export default connect(mapStateToProps)(FindReservationButton)
