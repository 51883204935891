import React from 'react'
import { connect } from 'react-redux'
import styles from './footerLogo.module.css'
import SokoshotelsLogo from '../../../../static/svgs/sokosHotelsLogoDark.svg'

const Logo = props => {
  let pathUrl = '/'
  if (props.locale === '/' || props.locale === '/fi/' || props.reduxLanguage === 'fi') {
    pathUrl = '/'
  }
  if (props.locale?.includes('/sv/') || props.reduxLanguage === 'sv') {
    pathUrl = '/sv'
  }
  if (props.locale?.includes('/en/') || props.reduxLanguage === 'en') {
    pathUrl = '/en'
  }
  if (props.locale?.includes('/et/') || props.reduxLanguage === 'et') {
    pathUrl = '/et'
  }

  return (
    <div className={styles.logoWrapper}>
      <a href={`${process.env.GATSBY_SH_URL}${pathUrl}`}>
        <SokoshotelsLogo alt="sokoshotels" />
      </a>
    </div>
  )
}

const mapStateToProps = state => ({
  reduxLanguage: state.language,
})

export default connect(mapStateToProps)(Logo)
