import React from 'react'
import NavHeader from './navHeader/navHeader'
import NavLinks from './navLinks/navLinks'
import navigationStyles from './navigation.module.css'

const Navigation = (props) => {
  return (
    <div className={navigationStyles.navigation}>
    <NavHeader locale={props.locale} />
    <NavLinks locale={props.locale} />
  </div>
  )
}

export default Navigation;