/**
 * @param {object} event
 * @param {string} event.event
 * @param {string} event.category
 * @param {string} event.action
 * @param {string} [event.label]
 */

export const trackEvent = event => {
  // typeof check required for the build to pass
  if (typeof window !== 'undefined') {
    if (typeof window.dataLayer === 'undefined') window.dataLayer = []
    window.dataLayer.push(event)
  }
}

/**
 * @param {string} action
 * @param {string} [label]
 */

export const trackFilterEvent = (action, label) => {
  trackEvent({
    event: 'search_and_filtering',
    category: 'search_and_filtering',
    action,
    label,
  })
}

/**
 * @param {object}  event
 * @param {string}  event.action
 * @param {string}  event.label
 * @param {*}       [event.value]
 */

export const trackErrorEvent = event => {
  // Set event prop for easier dataLayer pick up
  event.event = event.category = 'Error'
  return trackEvent(event)
}
