import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink, navigate as gatsbyNavigate } from 'gatsby'
import { IntlContextConsumer } from './intl-context'
import { connect } from 'react-redux'

// getting dispatch prop to circumvent a bug with react redux that passes it to down to the anchor element, which causes an error in the console
const Link = ({ reduxState, to, state, language, children, onClick, disableLocalizeLink, dispatch, ...rest }) => (
  <IntlContextConsumer>
    {intl => {
      const languageLink = language || intl.language
      let link = intl.routed || language ? `/${languageLink}${to}` : `${to}`

      if (!disableLocalizeLink) {
        // Case: venuePage
        if (intl.localizedPaths) link = `/${languageLink}${intl.localizedPaths[languageLink]}`
        // Case: showReservation
        if (to == '/reservation') link = `/${languageLink}${intl.localizedReservationPaths[languageLink]}`
        // Case: home
        if (to == '/') link = `/${languageLink}/`
      }

      const handleClick = e => {
        if (language) localStorage.setItem('gatsby-intl-language', language)
        if (onClick) onClick(e)
      }

      return (
        <GatsbyLink {...rest} to={link} state={state ? state : reduxState} onClick={handleClick}>
          {children}
        </GatsbyLink>
      )
    }}
  </IntlContextConsumer>
)

Link.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  language: PropTypes.string,
}

Link.defaultProps = {
  to: '',
}

const mapStateToProps = state => ({
  reduxState: state,
})

export default connect(mapStateToProps, null)(Link)

export const navigate = (to, options) => {
  if (typeof window === 'undefined') {
    return
  }

  const { language, routed, localizedReservationPaths } = window.___gatsbyIntl
  let link = routed ? `/${language}${to}` : `${to}`

  // Case: showReservation
  if (to == '/reservation') link = `/${language}${localizedReservationPaths[language]}`

  gatsbyNavigate(link, options)
}

export const changeLocale = (language, to) => {
  if (typeof window === 'undefined') {
    return
  }
  const { routed } = window.___gatsbyIntl

  const removePrefix = pathname => {
    const base = typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
    if (base && pathname.indexOf(base) === 0) {
      pathname = pathname.slice(base.length)
    }
    return pathname
  }

  const removeLocalePart = pathname => {
    if (!routed) {
      return pathname
    }
    const i = pathname.indexOf(`/`, 1)
    return pathname.substring(i)
  }

  const pathname = to || removeLocalePart(removePrefix(window.location.pathname))
  // TODO: check slash
  const link = `/${language}${pathname}${window.location.search}`
  localStorage.setItem('gatsby-intl-language', language)
  gatsbyNavigate(link)
}
