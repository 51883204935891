import React from 'react'
import styles from './navHeader.module.css'
import NavLogo from './navLogo'
import LanguageSelection from './languageSelection'
import BurgerMenu from './burgerMenu'
import FindReservationButton from './findReservationButton'

export default (props) => {
  return (
    <div className={styles.wrapper}>
      <NavLogo locale={props.locale} />
      <div className={styles.subWrapper}>
        <LanguageSelection />
        <FindReservationButton />
        <BurgerMenu />
      </div>
    </div>
  )
}
