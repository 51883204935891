import gql from 'graphql-tag'
import { handleCancelReservationMessage, handleReservationMutationError } from '../messageService'
import { trackEvent, trackErrorEvent } from '../analyticsService'

export const cancelReservation = (
  reduxLanguage,
  reduxWaitingForMutationResponse, //
  reduxPreReservation, //
  reduxReservation, //
  dispatchMutatingReservationTrue, //
  changeUserMessage, // optional
  dispatchCancelReservation, //
  dispatchCreateReservation, //
  dispatchConfirmReservation, //
  dispatchReservation, //
  dispatchChangeReservationURLPart, //
  dispatchChangeReservationLastname, //
  dispatchChangeReservationId, //
  dispatchMutatingReservationFalse, //
  client, //
  afterCancelReservationSuccess // optional
) => {
  if (reduxWaitingForMutationResponse) return
  if (!reduxPreReservation && !reduxReservation) return

  const reservationID = reduxPreReservation ? reduxPreReservation.id : reduxReservation.id

  const customerLastName = reduxPreReservation ? '' : reduxReservation.people.customer.lastName

  dispatchMutatingReservationTrue()
  if (changeUserMessage) changeUserMessage(handleReservationMutationError(undefined))

  const mutation = reduxPreReservation
    ? {
        mutation: CANCEL_PRE_RESERVATION,
        variables: { reservationID },
      }
    : {
        mutation: CANCEL_CONFIRMED_RESERVATION,
        variables: { reservationID, customerLastName },
      }

  client
    .mutate(mutation)
    .then(result => {
      if (!result.data || (!result.data.cancelConfirmedReservation && !result.data.cancelPreReservation)) {
        this.props.changeUserMessage(handleReservationMutationError('Missing response data error', reduxLanguage))
        dispatchCancelReservation(undefined)
        dispatchMutatingReservationFalse()
        trackErrorEvent({
          action: 'cancelReservation',
          label: 'Missing response data',
          value: result,
        })
        return
      }
      const cancelReservation = result.data.cancelPreReservation ? result.data.cancelPreReservation : result.data.cancelReservation
      dispatchCancelReservation(cancelReservation)
      dispatchCreateReservation(undefined)
      dispatchConfirmReservation(undefined)
      dispatchReservation(undefined)
      dispatchChangeReservationURLPart('')
      dispatchChangeReservationLastname('')
      dispatchChangeReservationId('')
      dispatchMutatingReservationFalse()
      if (changeUserMessage) changeUserMessage(handleCancelReservationMessage(reservationID, reduxLanguage))
      if (afterCancelReservationSuccess) afterCancelReservationSuccess()

      if (result.data.cancelConfirmedReservation) {
        trackEvent({
          event: 'refund',
          ecommerce: {
            refund: {
              actionField: { id: result.data.cancelConfirmedReservation.id },
            },
          },
        })
      }
    })
    .catch(error => {
      if (changeUserMessage) changeUserMessage(handleReservationMutationError(error, reduxLanguage))
      // TODO: Should error situation be handled better? There might still be prereservation valid for 15 minutes because cancelling wasn't successfull
      // props.dispatchCreateReservation(undefined)
      dispatchMutatingReservationFalse()
    })
}

/* #region CancelReservation queries */
const CANCEL_PRE_RESERVATION = gql`
  mutation cancelPreReservation($reservationID: ID!) {
    cancelPreReservation(reservationID: $reservationID) {
      id
    }
  }
`

const CANCEL_CONFIRMED_RESERVATION = gql`
  mutation cancelConfirmedReservation($reservationID: ID!, $customerLastName: String!) {
    cancelConfirmedReservation(reservationID: $reservationID, customerLastName: $customerLastName) {
      id
    }
  }
`
