import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { IntlContextConsumer } from '../../../plugins/gatsby-plugin-intl-custom'

function SEO({ description, meta, title, imageURL }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const availableLocales = ['fi_FI', 'sv_FI', 'en_GB', 'et_EE']
  // TODO: Use default image url for twitter:image and og:image if one is not defined
  return (
    <IntlContextConsumer>
      {intl => {
        return (
          <Helmet
            htmlAttributes={{
              lang: intl.language,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: `${site.siteMetadata.siteUrl}/${intl.language + intl.originalPath}`,
              },
              {
                property: `og:image`,
                content: imageURL || '',
              },
              {
                property: `og:locale`,
                content: availableLocales.find(locale => locale.startsWith(intl.language)),
              },
              ...availableLocales
                .filter(locale => !locale.startsWith(intl.language))
                .map(locale => ({
                  property: `og:locale:alternate`,
                  content: locale,
                })),
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: imageURL || '',
              },
            ].concat(meta)}
          />
        )
      }}
    </IntlContextConsumer>
  )
}
SEO.defaultProps = {
  meta: [],
  description: ``,
}
SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
