import React from 'react'
import styles from './languageSelection.module.css'
import ArrowDown from '../../../../static/svgs/arrowdown.svg'
import { IntlContextConsumer, Link } from '../../../../plugins/gatsby-plugin-intl-custom'

/*
  Showing and hiding the language selector dropdown is done using the css:focus-within pseudo selector
*/

const LanguageSelection = () => (
  <IntlContextConsumer>
    {/* All props come form gatsby-plugin-intl-custom */
    ({ language: locale, languages, originalPath }) => (
      <div className={styles.container} tabIndex="0" role="button" aria-label="language-selection">
        <div className={styles.Selector}>
          <p className={styles.DropdownText}>{locale}</p>
          <div className={styles.arrowDown}>
            <ArrowDown alt="arrow down" />
          </div>
        </div>
        <div className={styles.SelectionListWrapper}>
          <div className={styles.SelectionList}>
            {languages.map(language => (
                <Link to={originalPath} replace language={language} hrefLang={language} className={styles.SelectionItem} key={language} tabIndex="0" rel="alternate">
                  {language}
                </Link>
            ))}
          </div>
        </div>
      </div>
    )}
  </IntlContextConsumer>
)

export default LanguageSelection
