import React, { useState } from 'react'
import { FormattedMessage, IntlContextConsumer, Link } from '../../../../plugins/gatsby-plugin-intl-custom'
import navLinksStyles from '../navLinks/navLinks.module.css'
import fonts from '../../../styles/fonts.module.css'

/*
  Showing and hiding the language selector dropdown is done using the css:focus-within pseudo selector
*/

const LanguageSelectionBurger = () => {
  const [langsVisible, setLangsVisible] = useState(false)

  return (
    <IntlContextConsumer>
      {/* All props come form gatsby-plugin-intl-custom */
      ({ language: locale, languages, originalPath }) => (
        <div id="languageSelectionBurger" className={navLinksStyles.subRibbonBurger}>
          <div className={navLinksStyles.subElementsBurger}>
            {!langsVisible && (
              // eslint-disable-next-line
              <div className={navLinksStyles.subElementBurger} onClick={() => setLangsVisible(true)}>
                <FormattedMessage id="header.language" />
                <span className={navLinksStyles.subLanguage}>{locale}</span>
                <svg className={navLinksStyles.subArrow} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill-rule="evenodd" d="M14.94 12 7.97 5.03l1.06-1.06L17.06 12l-8.03 8.03-1.06-1.06L14.94 12Z" fill="currentColor"></path>
                </svg>
              </div>
            )}
            {langsVisible && (
              // eslint-disable-next-line
              <div className={`${navLinksStyles.subElementBurger} ${navLinksStyles.subLanguageWrapper}`} onClick={() => setLangsVisible(false)}>
                <svg className={navLinksStyles.subArrowBack} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                  <path fill-rule="evenodd" d="m9.06 12 6.97 6.97-1.06 1.06L6.94 12l8.03-8.03 1.06 1.06L9.06 12Z" fill="currentColor"></path>
                </svg>
                <span className={`${navLinksStyles.subBack} ${fonts.smallParagraph}`}>
                  <FormattedMessage id="header.goback" />
                </span>
                <span className={`${navLinksStyles.subBackLanguage}`}>
                  <FormattedMessage id="header.language" />
                </span>
              </div>
            )}
            {langsVisible &&
              languages.map(language => (
                <Link
                  className={`${navLinksStyles.subElementBurger} ${navLinksStyles.subLanguageWrapper}`}
                  to={originalPath}
                  replace
                  language={language}
                  hrefLang={language}
                  key={language}
                  tabIndex="0"
                  rel="alternate"
                >
                  <span className={fonts.smallParagraph}>{language}</span>
                  {language === locale && (
                    <svg className={navLinksStyles.subCheckmark} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill-rule="evenodd" d="m10.5 13.94 6.47-6.47 1.06 1.06-7.53 7.53-4.03-4.03 1.06-1.06 2.97 2.97Z" fill="currentColor"></path>
                    </svg>
                  )}
                </Link>
              ))}
          </div>
        </div>
      )}
    </IntlContextConsumer>
  )
}

// const LanguageSelectionBurger2 = () => (
//   <IntlContextConsumer>
//     {/* All props come form gatsby-plugin-intl-custom */
//     ({ language: locale, languages, originalPath }) => (
//       <div className={styles.container} tabIndex="0" role="button" aria-label="language-selection">
//         <div className={styles.Selector}>
//           <p className={styles.DropdownText}>{locale}</p>
//           <ArrowDown alt="arrow down" className={styles.DropdownArrow} />
//         </div>
//         <div className={styles.SelectionList}>
//           {languages.map(language => (
//             <Link to={originalPath} replace language={language} hrefLang={language} className={styles.SelectionItem} key={language} tabIndex="0" rel="alternate">
//               {language}
//             </Link>
//           ))}
//         </div>
//       </div>
//     )}
//   </IntlContextConsumer>
// )

export default LanguageSelectionBurger
