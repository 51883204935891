import React from 'react'
import { connect } from 'react-redux'
import { Link, FormattedMessage } from '../../../../plugins/gatsby-plugin-intl-custom'
import navLinksStyles from '../navLinks/navLinks.module.css'

const FindReservationButtonBurger = ({ reduxState }) => {
  const stateForRedirection = { ...reduxState }
  stateForRedirection.data = {
    edges: [],
    venueAvailability: undefined,
    venueProducts: [],
    reservation: undefined,
    createReservation: undefined,
    confirmReservation: undefined,
    cancelReservation: undefined,
    queryError: undefined,
  }
  stateForRedirection.search.reservationId = ''
  stateForRedirection.search.reservationLastname = ''
  stateForRedirection.search.reservationURLEncryptedPart = ''

  // TODO: If already in location /reservation then just close menu

  return (
    <div id="findReservationButtonBurger" className={navLinksStyles.subRibbonBurger}>
      <div className={navLinksStyles.subElementsBurger}>
        <Link className={navLinksStyles.subElementBurger} to="/reservation" state={stateForRedirection}>
          <FormattedMessage id="header.findReservation" />
          <svg className={navLinksStyles.subArrow}  fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill-rule="evenodd" d="M16.102 16.162a8 8 0 1 1 1.06-1.06l4.368 4.368-1.06 1.06-4.368-4.368ZM17.5 10a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0Z" fill="currentColor"></path>
          </svg>
        </Link>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  reduxState: state,
})

export default connect(mapStateToProps)(FindReservationButtonBurger)
