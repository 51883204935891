import React from 'react'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import {
  changeCancelReservation,
  changeCreateReservation,
  changeConfirmReservation,
  changeReservation,
  setMutatingReservationTrue,
  setMutatingReservationFalse,
  changeReservationLastname,
  changeReservationId,
  changeReservationURLPart,
} from '../../../state/reduxApp'
import { handleCancelReservationMessage } from '../../../services/messageService'
import { cancelReservation } from '../../../services/reservation/cancelReservation'
import Button from '../../common/defaultButton/defaultButton'

/*  Props
    -changeUserMessage
    -showConfirmedReservationCancellingButton
*/
class CancelButton extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props)
  }

  renderCancelReservationButton() {
    if (this.props.reduxPreReservation || this.props.reduxConfirmReservation || this.props.reduxReservation)
      return (
        <Button
          type="smallDestructive" //
          onClick={() =>
            cancelReservation(
              this.props.reduxLanguage, //
              this.props.reduxWaitingForMutationResponse, //
              this.props.reduxPreReservation, //
              this.props.reduxReservation, //
              this.props.dispatchMutatingReservationTrue, //
              this.props.changeUserMessage, //
              this.props.dispatchCancelReservation, //
              this.props.dispatchCreateReservation, //
              this.props.dispatchConfirmReservation, //
              this.props.dispatchReservation, //
              this.props.dispatchChangeReservationURLPart, //
              this.props.dispatchChangeReservationLastname, //
              this.props.dispatchChangeReservationId, //
              this.props.dispatchMutatingReservationFalse, //
              this.props.client, //
              this.props.afterCancelReservationSuccess //
            )
          } //
          buttonText={this.props.buttonText} //
          isLoadingButton={this.props.reduxWaitingForMutationResponse} //
          disabled={this.props.reduxWaitingForMutationResponse}
        />
      )
    return null
  }
  renderCancelCreation() {
    if (!this.props.reduxCancelReservation) return null
    const cancelId = this.props.reduxCancelReservation ? this.props.reduxCancelReservation.id : ''
    return <div>{handleCancelReservationMessage(cancelId, this.props.reduxLanguage)}</div>
  }
  /* #endregion */

  render() {
    return <div>{this.renderCancelReservationButton()}</div>
  }
}

const mapStateToProps = state => ({
  reduxPreReservation: state.data.createReservation,
  reduxLanguage: state.language,
  reduxCancelReservation: state.data.cancelReservation,
  reduxConfirmReservation: state.data.confirmReservation,
  reduxReservation: state.data.reservation,
  reduxWaitingForMutationResponse: state.queryStatuses.mutatingReservation,
})

const mapDispatchToProps = dispatch => ({
  dispatchCancelReservation: callbackLink => dispatch(changeCancelReservation(callbackLink)),
  dispatchCreateReservation: callbackLink => dispatch(changeCreateReservation(callbackLink)),
  dispatchConfirmReservation: callbackLink => dispatch(changeConfirmReservation(callbackLink)),
  dispatchReservation: callbackLink => dispatch(changeReservation(callbackLink)),
  dispatchChangeReservationId: callbackLink => dispatch(changeReservationId(callbackLink)),
  dispatchChangeReservationLastname: callbackLink => dispatch(changeReservationLastname(callbackLink)),
  dispatchChangeReservationURLPart: callbackLink => dispatch(changeReservationURLPart(callbackLink)),
  dispatchMutatingReservationTrue: callbackLink => dispatch(setMutatingReservationTrue(callbackLink)),
  dispatchMutatingReservationFalse: callbackLink => dispatch(setMutatingReservationFalse(callbackLink)),
})

export default withApollo(connect(mapStateToProps, mapDispatchToProps)(CancelButton))

/* #endregion */
