const message = (language, reservationNumber = undefined) => {
  let message = null
  switch (language) {
    case 'fi':
      message = {
        venueNotAvailable: 'Tila ei ole vapaa valitsemanasi ajankohtana.',
        error: 'Tapahtui virhe! Ole hyvä ja yritä uudelleen.',
        reservationCancelled: `Varauksesi numero ${reservationNumber} on peruttu.`,
      }
      break
    case 'en':
      message = {
        venueNotAvailable: 'Venue is not available at selected time.',
        error: 'There was an error. Please try again.',
        reservationCancelled: `Your reservation ${reservationNumber} has been cancelled.`,
      }
      break
    case 'sv':
      message = {
        venueNotAvailable: 'Lokalen är inte ledig vid vald tid.',
        error: 'Ett fel har inträffat. Var god och försöka på nytt.',
        reservationCancelled: `Din bokning ${reservationNumber} har avbokats.`,
      }
      break
    default:
      message = {
        venueNotAvailable: 'Venue is not available at selected time.',
        error: 'There was an error. Please try again.',
        reservationCancelled: `Your reservation ${reservationNumber} has been cancelled.`,
      }
  }
  return message
}

export const TYPE_SUCCESS = 'TYPE_SUCCESS'
export const TYPE_ERROR = 'TYPE_ERROR'

export const handleReservationMutationError = (error, language = undefined) => {
  if (!error) return undefined
  if (typeof error === 'object') error = JSON.stringify(error)

  let messageBody = message(language).error

  if (error.includes('403')) {
    messageBody = message(language).venueNotAvailable
  }

  return {
    body: messageBody,
    type: TYPE_ERROR,
    visibleForReservation: true,
    visibleForPrereservation: true,
  }
}

export const handleCancelReservationMessage = (reservationNumber, language = undefined) => {
  if (!reservationNumber) return ''

  let messageBody = message(language, reservationNumber).reservationCancelled

  return {
    body: messageBody,
    type: TYPE_SUCCESS,
    visibleForReservation: true,
    visibleForPrereservation: true,
  }
}
