import React from 'react'
import navLinksStyles from './navLinks.module.css'

const subHeaders = props => {
  const { reduxLang } = props
  if (!props?.data[reduxLang]?.MeetingsAndEvents?.sublinks) return null

  return Object.values(props.data[reduxLang].MeetingsAndEvents.sublinks).map(item => {
    const itemLink = `${process.env.GATSBY_SH_URL}${item.link}`
    return (
      <a className={navLinksStyles.subElement} key={item.name} href={itemLink} onClick={e => props.openConfirmationPopup(e, itemLink, true)}>
        {item.name}
      </a>
    )
  })
}

const subHeadersBurger = props => {
  const { reduxLang } = props
  if (!props?.data[reduxLang]?.MeetingsAndEvents?.sublinks) return null

  return Object.values(props.data[reduxLang].MeetingsAndEvents.sublinks).map(item => {
    const itemLink = `${process.env.GATSBY_SH_URL}${item.link}`
    return (
      <div className={navLinksStyles.subRibbonBurgerWrapper}>
        <a className={navLinksStyles.subElementBurger} key={item.name} href={itemLink} onClick={e => props.openConfirmationPopup(e, itemLink, true)}>
          {item.name}
          <svg className={navLinksStyles.subArrow} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fill-rule="evenodd" d="M14.94 12 7.97 5.03l1.06-1.06L17.06 12l-8.03 8.03-1.06-1.06L14.94 12Z" fill="currentColor"></path>
          </svg>
        </a>
      </div>
    )
  })
}

const SubRibbon = props => {
  return (
    <>
      <div className={navLinksStyles.subRibbon}>
        <div className={`${navLinksStyles.wrapper} ${navLinksStyles.subElements}`}>{subHeaders(props)}</div>
      </div>
      <div id="subRibbonBurger" className={navLinksStyles.subRibbonBurger}>
        <div className={`${navLinksStyles.wrapper} ${navLinksStyles.subElementsBurger}`}>{subHeadersBurger(props)}</div>
      </div>
    </>
  )
}

export default SubRibbon
